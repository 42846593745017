import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";


import moroccandish1 from "images/menu/moroccandish/moroccandish1.webp";
import moroccandish2 from "images/menu/moroccandish/moroccandish2.webp";
import moroccandish3 from "images/menu/moroccandish/moroccandish3.webp";
import moroccandish4 from "images/menu/moroccandish/moroccandish4.webp";
import moroccandish5 from "images/menu/moroccandish/moroccandish5.webp";
import moroccandish6 from "images/menu/moroccandish/moroccandish6.webp";
import moroccandish7 from "images/menu/moroccandish/moroccandish7.webp";
import moroccandish8 from "images/menu/moroccandish/moroccandish8.webp";
import moroccandish9 from "images/menu/moroccandish/moroccandish9.webp";
import moroccandish10 from "images/menu/moroccandish/moroccandish10.webp";
import moroccandish11 from "images/menu/moroccandish/moroccandish11.webp";
import moroccandish12 from "images/menu/moroccandish/moroccandish12.webp";
import moroccandish13 from "images/menu/moroccandish/moroccandish13.webp";
import moroccandish14 from "images/menu/moroccandish/moroccandish14.webp";
import moroccandish15 from "images/menu/moroccandish/moroccandish15.webp";
import moroccandish16 from "images/menu/moroccandish/moroccandish16.webp";

import breakfast1 from "images/menu/breakfast/breakfast1.webp";
import breakfast2 from "images/menu/breakfast/breakfast2.webp";
import breakfast3 from "images/menu/breakfast/breakfast3.webp";
import breakfast4 from "images/menu/breakfast/breakfast4.webp";
import breakfast5 from "images/menu/breakfast/breakfast5.webp";
import breakfast6 from "images/menu/breakfast/breakfast6.webp";
import breakfast7 from "images/menu/breakfast/breakfast7.webp";
import breakfast8 from "images/menu/breakfast/breakfast8.webp";

import starter1 from "images/menu/starter/starter1.webp";
import starter2 from "images/menu/starter/starter2.webp";
import starter3 from "images/menu/starter/starter3.webp";
import starter4 from "images/menu/starter/starter4.webp";
import starter5 from "images/menu/starter/starter5.webp";
import starter6 from "images/menu/starter/starter6.webp";
import starter7 from "images/menu/starter/starter7.webp";
import starter8 from "images/menu/starter/starter8.webp";
import starter9 from "images/menu/starter/starter9.webp";
import starter10 from "images/menu/starter/starter10.webp";

import vegetarian1 from "images/menu/vegetarian/vegetarian1.webp";
import vegetarian2 from "images/menu/vegetarian/vegetarian2.webp";
import vegetarian3 from "images/menu/vegetarian/vegetarian3.webp";
import vegetarian4 from "images/menu/vegetarian/vegetarian4.webp";
import vegetarian5 from "images/menu/vegetarian/vegetarian5.webp";
import vegetarian6 from "images/menu/vegetarian/vegetarian6.webp";
import vegetarian7 from "images/menu/vegetarian/vegetarian7.webp";
import vegetarian8 from "images/menu/vegetarian/vegetarian8.webp";

import fastfood1 from "images/menu/fastfood/fastfood1.webp";
import fastfood2 from "images/menu/fastfood/fastfood2.webp";
import fastfood3 from "images/menu/fastfood/fastfood3.webp";
import fastfood4 from "images/menu/fastfood/fastfood4.webp";
import fastfood5 from "images/menu/fastfood/fastfood5.webp";
import fastfood6 from "images/menu/fastfood/fastfood6.webp";
import fastfood7 from "images/menu/fastfood/fastfood7.webp";
import fastfood8 from "images/menu/fastfood/fastfood8.webp";
import fastfood9 from "images/menu/fastfood/fastfood9.webp";
import fastfood10 from "images/menu/fastfood/fastfood10.webp";

import grill1 from "images/menu/grill/grill1.webp";
import grill2 from "images/menu/grill/grill2.webp";
import grill3 from "images/menu/grill/grill3.webp";
import grill4 from "images/menu/grill/grill4.webp";
import grill5 from "images/menu/grill/grill5.webp";
import grill6 from "images/menu/grill/grill6.webp";
import grill7 from "images/menu/grill/grill7.webp";
import grill8 from "images/menu/grill/grill8.webp";
import grill9 from "images/menu/grill/grill9.webp";

import fish1 from "images/menu/fish/fish1.webp";
import fish2 from "images/menu/fish/fish2.webp";
import fish3 from "images/menu/fish/fish3.webp";
import fish4 from "images/menu/fish/fish4.webp";
import fish5 from "images/menu/fish/fish5.webp";
import fish6 from "images/menu/fish/fish6.webp";

import juice1 from "images/menu/juice/juice1.webp";
import juice2 from "images/menu/juice/juice2.webp";
import juice3 from "images/menu/juice/juice3.webp";
import juice4 from "images/menu/juice/juice4.webp";

import hotdrink1 from "images/menu/hotdrink/hotdrink1.webp";
import hotdrink2 from "images/menu/hotdrink/hotdrink2.webp";
import hotdrink3 from "images/menu/hotdrink/hotdrink3.webp";
import hotdrink4 from "images/menu/hotdrink/hotdrink4.webp";
import hotdrink5 from "images/menu/hotdrink/hotdrink5.webp";
import hotdrink6 from "images/menu/hotdrink/hotdrink6.webp";
import hotdrink7 from "images/menu/hotdrink/hotdrink7.webp";
import hotdrink8 from "images/menu/hotdrink/hotdrink8.webp";
import hotdrink9 from "images/menu/hotdrink/hotdrink9.webp";
import hotdrink10 from "images/menu/hotdrink/hotdrink10.webp";
import hotdrink11 from "images/menu/hotdrink/hotdrink11.webp";
import hotdrink12 from "images/menu/hotdrink/hotdrink12.webp";

import milkshake1 from "images/menu/milkshake/milkshake1.webp";
import milkshake2 from "images/menu/milkshake/milkshake2.webp";
import milkshake3 from "images/menu/milkshake/milkshake3.webp";
import milkshake4 from "images/menu/milkshake/milkshake4.webp";
import milkshake5 from "images/menu/milkshake/milkshake5.webp";
import milkshake6 from "images/menu/milkshake/milkshake6.webp";
import milkshake7 from "images/menu/milkshake/milkshake7.webp";
import milkshake8 from "images/menu/milkshake/milkshake8.webp";
import milkshake9 from "images/menu/milkshake/milkshake9.webp";
import milkshake10 from "images/menu/milkshake/milkshake10.webp";


const Header = tw(SectionHeading)`text-gray-600`;
const TabsControl = tw.div`flex flex-wrap justify-between bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-6`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(169, 2, 4, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  heading = "Checkout the Menu",
  tabs = {
    "Moroccan": [
      {
        imageSrc: moroccandish1,
        title: "LAMB COUSCOUS",
        content: "Couscous, lamb, mixed vegetables, spices",
        price: "£14.90",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: moroccandish2,
        title: "LAMB & PLUMS TAJINE",
        content: "Lamb, onions, plums, spices",
        price: "£12.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: moroccandish3,
        title: "LAMB TAJINE",
        content: "Lamb, mixed vegetables, spices",
        price: "£13.90",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: moroccandish4,
        title: "VEGETABLES CHICKEN TAJINE",
        content: "Chicken, mixed vegetables, spices",
        price: "£11.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: moroccandish5,
        title: "CHICKEN OLIVES TAJINE",
        content: "Chicken, olives, fries, spices",
        price: "£10.50"
      },
      {
        imageSrc: moroccandish6,
        title: "CHICKEN RFISSA",
        content: "Chicken, lamsamn, lentils, spices",
        price: "£14.90",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: moroccandish7,
        title: "CHICKEN BASTILLA",
        content: "Chicken, almonds, eggs, onions, spices",
        price: "£15.90",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: moroccandish8,
        title: "CHICKEN COUSCOUS",
        content: "Couscous, chicken, mixed vegetables, spices",
        price: "£12.90",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: moroccandish9,
        title: "CHICKEN COUSCOUS TFAYA",
        content: "Garnish of caramelized onions and raisins",
        price: "£16.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: moroccandish10,
        title: "KOFTA BEEF TAGINE",
        content: "Minced meat, onion, tomatoes, egg",
        price: "£10.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: moroccandish11,
        title: "KOFTA CHICKEN TAGINE",
        content: "Minced chicken, onion, tomatoes, egg",
        price: "£9.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: moroccandish12,
        title: "CLASSIC CHICKEN OLIVES",
        content: "Chicken, olives, potatoes, spices",
        price: "£10.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: moroccandish13,
        title: "MARGUEZ COUSCOUS",
        content: "Couscous, beef marguez, mixed vegetables, spices",
        price: "£14.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: moroccandish14,
        title: "AL ASSALA TAJINE",
        content: "Lamb, onion, boiled egg, plums, spices",
        price: "£18.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: moroccandish15,
        title: "LAMB & ARTICHOKE TAJINE",
        content: "Lamb, artichoke, peas, spices",
        price: "£13.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: moroccandish16,
        title: "LAMB & QUINCE TAJINE",
        content: "Lamb, onion, quince, spices",
        price: "£13.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      }
    ],
    Breakfast: [
      {
        imageSrc: breakfast1,
        title: "AL ASSALA BREAKFAST",
        content: "Marghaz(sausages), spring onions, eggs, served with moroccan tea and bread",
        price: "£9.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: breakfast2,
        title: "AL ATLAS BREAKFAST",
        content: "Eggs, cheese, spring onions, coriander, spices, served with moroccan tea and bread",
        price: "£8.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: breakfast3,
        title: "MARRAKECH BREAKFAST",
        content: "Eggs, salami, spring onions, coriander, served with moroccan tea and bread",
        price: "£8.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: breakfast4,
        title: "FES BREAKFAST",
        content: "Eggs, spring onions, lkhlii (dry lamb meat) served with moroccan tea and bread",
        price: "£10.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: breakfast5,
        title: "CHAFCHAOAN BREAKFAST",
        content: "Eggs, spring onions, peas, coriander, served with moroccan tea and bread",
        price: "£8.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: breakfast6,
        title: "AGADIR BREAKFAST",
        content: "Egg omelete, spring onions, parsley, served with moroccan tea and bread",
        price: "£7.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: breakfast7,
        title: "SHAWIYA BREAKFAST",
        content: "2 mssamens, olive oil, honey or cheese, served with moroccan tea",
        price: "£8.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: breakfast8,
        title: "ESSAOUIRA BREAKFAST",
        content: "Eggs, chicken, spring onions, coriander, served with moroccan tea and bread",
        price: "£9.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      }
    ],
    Starters: [
      {
        imageSrc: starter1,
        title: "SHAKSHOUKA",
        content: "Cooked peppers, tomatoes, onions, parsley",
        price: "£6.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: starter2,
        title: "MOROCCAN SALAD",
        content: "Lettuce, peppers, tomatoes, onions, parsley",
        price: "£5.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: starter3,
        title: "ZALOUK",
        content: "Cooked aubergine, tomatoes, parsley",
        price: "£7.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: starter4,
        title: "FULL MODAMASS",
        content: "Fava beans, tomatoes, onions, coriander, parsley",
        price: "£7.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: starter5,
        title: "HUMMUS",
        content: "Chickpeas creme, olive oil, tahina, lemon, cumin",
        price: "£6.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: starter6,
        title: "CHILLY POTATOES",
        content: "Fried potatoes wedges, chilly",
        price: "£3.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: starter7,
        title: "MIXED STARTER",
        content: "Salad, hummus, potatoes, falafel, grape leaves, aubergine cream",
        price: "£9.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: starter8,
        title: "FALAFEL",
        content: "Falafel, hummus",
        price: "£7.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: starter9,
        title: "EGGS TAJINE",
        content: "Eggs, tomatoes, onions, peppers, coriander, spices",
        price: "£8.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: starter10,
        title: "HARIRA (CAN VARY)",
        content: "Chickpeas, tomatoes sauce, lentils, flour, parsley, coriander, celery, spices",
        price: "£5.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      }
    ],
    Vegetarian: [
      {
        imageSrc: vegetarian1,
        title: "VEGETARIAN COUSCOUS",
        content: "Couscous, mixed vegetables, spices",
        price: "£10.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: vegetarian2,
        title: "VEGETARIAN TAJINE",
        content: "Mixed vegetables, spices",
        price: "£8.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: vegetarian3,
        title: "VEGETARIAN RICE",
        content: "Rice, mixed vegetables, spices",
        price: "£6.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: vegetarian4,
        title: "LEGUMES",
        content: "Lentils, spices or Beans, spices",
        price: "£7.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: vegetarian5,
        title: "BAKOULA",
        content: "Bakouli, limone, olives, garlic, parsly, coriander",
        price: "£7.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: vegetarian6,
        title: "FALAFEL WRAP",
        content: "Falafel, salad, sauce",
        price: "£7.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: vegetarian7,
        title: "FALAFEL PLATE",
        content: "Falafel wrap, salad, fries",
        price: "£10.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: vegetarian8,
        title: "SAFFA",
        content: "Hair pasta, icing sugar, cinnamon, almonds",
        price: "£11.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      }
    ],
    "Fast Food": [
      {
        imageSrc: fastfood1,
        title: "SHAWARMA WRAP",
        content: "Kebab, salad, sauce",
        price: "£5.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: fastfood2,
        title: "CLASSIC KEBAB",
        content: "Kebab, salad, sauce",
        price: "£5.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: fastfood3,
        title: "KEBAB PLATE",
        content: "Kebab, fries or rice, salad",
        price: "£9.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: fastfood4,
        title: "SHAWARMA",
        content: "Kebab wrap, cheese, salad, sauce",
        price: "£7.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: fastfood5,
        title: "SPECIAL KEBAB WRAP",
        content: "Kabab wrap, hummus, falafel, salad, potatoes",
        price: "£9.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: fastfood6,
        title: "KOFTA BEEF WRAP",
        content: "Kofta beef, salad, sauce",
        price: "£6.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: fastfood7,
        title: "KOFTA CHICKEN WRAP",
        content: "(homemade)",
        price: "£5.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: fastfood8,
        title: "CLASSIC MARGUEZ",
        content: "Marguez Sausage, salad, fries in side",
        price: "£6.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: fastfood9,
        title: "BEEF BURGER",
        content: "(homemade)",
        price: "£5.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: fastfood10,
        title: "CHICKEN BURGER",
        content: "(homemade)",
        price: "£4.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      }
    ],

    "Grill": [
      {
        imageSrc: grill1,
        title: "CHICKEN SKEWERS",
        content: "Chicken, potatoes, salad, rice",
        price: "£11.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: grill2,
        title: "CHICKEN KOFTA",
        content: "Chicken kofta, potatoes, salad, rice",
        price: "£10.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: grill3,
        title: "MIXED GRILL",
        content: "Beef kofta, chicken kofta, 1 chicken skewer, rice, potatoes, salad",
        price: "£14.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: grill4,
        title: "LAMB CHOP",
        content: "3 Lambs chop, potaetos, rice, salad",
        price: "£14.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: grill5,
        title: "BEEF KOFTA",
        content: "Beef kofta, rice, potatoes, salad",
        price: "£12.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: grill6,
        title: "AL ASSALA BEEF KOFTA",
        content: "Kofta beef grill, onions, grill tomatoes, rice",
        price: "£13.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: grill7,
        title: "LAMB SKEWERS",
        content: "Lamb Skewers, grilled onions, grilled tomatoes, rice",
        price: "£13.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: grill8,
        title: "BOULFAF (LAMB LIVER)",
        content: "Liver Lamb, grilled onions, grilled tomatoes, rice",
        price: "£15.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: grill9,
        title: "LARGE MIXED GRILL",
        content: "Beef kofta, chicken kofta, 2 chicken skewers, 2 marguez, 2 lamb chop, rice, potatoes, salad",
        price: "£30.90",
        url: "https://moroccofood.co.uk/contact-us-now"
      }
    ],
    Fish: [
      {
        imageSrc: fish1,
        title: "TAJINE KOFTA SARDINES",
        content: "Mince sardines, olives, peppers",
        price: "£12.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: fish2,
        title: "ORATA GRILL",
        content: "Grilled orata fish, salad, fries",
        price: "£14.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: fish3,
        title: "FRIED SARDINES",
        content: "3 fried sardines, tomatoes salad & onions",
        price: "£11.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: fish4,
        title: "GRILLED SARDINES",
        content: "3 grilled sardines, tomatoes salad & onions",
        price: "£13.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: fish5,
        title: "SALMON FISH",
        content: "Salmon fish & salad",
        price: "£13.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: fish6,
        title: "MIX FIED FISH",
        content: "Mix fried fish, salad, fries",
        price: "£25.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      }
    ],
    "Juices": [
      {
        imageSrc: juice1,
        title: "AVOCADO JUICE",
        content: "",
        price: "£3.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: juice2,
        title: "ORANGE JUICE",
        content: "",
        price: "£3.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: juice3,
        title: "STRAWBERY JUICE",
        content: "",
        price: "£3.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: juice4,
        title: "MINT LIME",
        content: "",
        price: "£3.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      },


    ],
    "Hot Drinks": [
      {
        imageSrc: hotdrink1,
        title: "MOCHA COFFEE",
        content: "",
        price: "£1.70",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: hotdrink2,
        title: "COFFEE AMERICANO",
        content: "",
        price: "£1.30",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: hotdrink3,
        title: "COFFEE LATTE",
        content: "",
        price: "£1.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: hotdrink4,
        title: "COFFEE MACCHIATTO",
        content: "",
        price: "£1.20",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: hotdrink5,
        title: "CAPPUCCINO",
        content: "",
        price: "£1.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: hotdrink6,
        title: "ESPRESSO",
        content: "",
        price: "£1.30",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: hotdrink7,
        title: "TURKISH TEA",
        content: "",
        price: "£1.30",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: hotdrink8,
        title: "ENGLISH TEA",
        content: "",
        price: "£1.20",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: hotdrink9,
        title: "GINGER TEA",
        content: "",
        price: "£1.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: hotdrink10,
        title: "MOROCCAN COFFEE (HALF-HALF)",
        content: "",
        price: "£1.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: hotdrink11,
        title: "MOROCCAN TEA WITH MINT",
        content: "",
        price: "£1.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: hotdrink12,
        title: "MOROCCAN COFFEE WITH SPICES",
        content: "",
        price: "£1.70",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
    ],
    "Milkshakes": [
      {
        imageSrc: milkshake1,
        title: "ZAAZAA",
        content: "",
        price: "£6.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: milkshake2,
        title: "FERRERO MILKSHAKE",
        content: "",
        price: "£4.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: milkshake3,
        title: "OREO MILKSHAKE",
        content: "",
        price: "£4.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: milkshake4,
        title: "WALNUT MILKSHAKE",
        content: "",
        price: "£5.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: milkshake5,
        title: "PEANUT MILKSHAKE",
        content: "",
        price: "£5.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: milkshake6,
        title: "ALMOND MILKSHAKE",
        content: "",
        price: "£5.00",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: milkshake7,
        title: "COCKTAIL MILKSHAKE",
        content: "",
        price: "£4.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: milkshake8,
        title: "AVOCADO MILKSHAKE",
        content: "",
        price: "£4.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: milkshake9,
        title: "STRAWBERRY MILKSHAKE",
        content: "",
        price: "£4.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      },
      {
        imageSrc: milkshake10,
        title: "BANANA MILKSHAKE",
        content: "",
        price: "£4.50",
        url: "https://moroccofood.co.uk/contact-us-now"
      }
    ],
  }
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container>
      <ContentWithPaddingXl>

        <Header>{heading}</Header>
        <TabsControl>
          {Object.keys(tabs).map((tabName, index) => (
            <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
              {tabName}
            </TabControl>
          ))}
        </TabsControl>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale: 1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale: 0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((card, index) => (
              <CardContainer key={index}>
                <Card className="group" href={card.url} initial="rest" whileHover="hover" animate="rest">
                  <CardImageContainer imageSrc={card.imageSrc}>

                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto"
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardButton>Order Now</CardButton>
                    </CardHoverOverlay>

                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardContent>{card.content}</CardContent>
                    <CardPrice>{card.price}</CardPrice>
                  </CardText>
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
