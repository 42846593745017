import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { ContentWithPaddingXl, Container } from "components/misc/Layouts";

const PrimaryBackgroundContainer = tw.div`py-12 lg:py-12 bg-primary-500 rounded-lg relative text-center`
const Row = tw.div`px-8 max-w-screen-lg mx-auto flex items-center justify-center relative z-10 flex-col lg:flex-row text-center lg:text-center`;

const ColumnContainer = tw.div`lg:w-full max-w-full justify-center`
const TextContainer = tw(ColumnContainer)``;
const TextHeader = tw.h3`text-gray-100 text-4xl sm:text-5xl font-bold`;
const Text = tw.h5`text-gray-100 text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black py-3`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`
const DecoratorBlob1 = tw(SvgDecoratorBlob1)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-primary-700 opacity-50`
const DecoratorBlob2 = tw(SvgDecoratorBlob1)`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-primary-700 opacity-50`
export default ({
  textHeader = "We'd love to hear from you!",
  text = "Developers all over the world are happily using Treact.",
  primaryLinkText = "Get Started",
  primaryLinkUrl = "http://timerse.com",
  secondaryLinkText = "Contact Us",
  secondaryLinkUrl = "http://google.com",
  pushDownFooter = true
}) => {
  return (
    <Container css={pushDownFooter && tw`hidden sm:block`}>
      <ContentWithPaddingXl>
        <PrimaryBackgroundContainer>
          <Row>
            <TextContainer>
              <TextHeader>{textHeader}</TextHeader>
              <Text>{text}</Text>
            </TextContainer>

          </Row>
          <DecoratorBlobContainer>
            <DecoratorBlob1 />
            <DecoratorBlob2 />
          </DecoratorBlobContainer>
        </PrimaryBackgroundContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
