import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import TabGrid from "components/cards/TabCardGrid.js";
import DownloadApp from "components/cta/GetStarted.js";
import Footer from "components/footers/SimpleFourColumn.js";
import Header from "components/headers/light.js";


export default () => {
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  return (
    <AnimationRevealPage disabled>
      <Header />

      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <TabGrid
        heading={
          <>
            Our <HighlightedText>MENU</HighlightedText>
          </>
        }
      />

      <DownloadApp
        text={<>
          <HighlightedTextInverse>01 21 82 03 003</HighlightedTextInverse></>}
      />

      <Footer />
    </AnimationRevealPage>
  );
}
