import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Features from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import DownloadApp from "components/cta/GetStarted.js";
import Footer from "components/footers/SimpleFourColumn.js";
import styled from "styled-components";
import sliderImage2 from "images/imgb2.webp";
import Header from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl, ContentWithPaddingSm } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

const HeaderTitle = tw(SectionHeading)`text-gray-600`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;


export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextGreen = tw.span`bg-primary-900 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-3 text-sm lg:text-base font-medium`;
  const imageCss = tw`rounded-4xl`;
  return (

    <AnimationRevealPage disabled>

      <Header />

      <Container>
        <ContentWithPaddingXl>

          <HeaderTitle>About <HighlightedText>Us</HighlightedText></HeaderTitle>

          <MainFeature
            subheading={<Subheading>Established Since 2018</Subheading>}
            heading={
              <>
                Al-Assala Restaurant
                <wbr /> <HighlightedText>A Feast of Flavours</HighlightedText>
              </>
            }
            description={
              <Description>
                Drawing inspiration from the diverse regions of Morocco, Al Assala presents a menu that celebrates the country's culinary heritage while adding a modern twist. From the bustling streets of Marrakech to the serene coastal towns, our dishes capture the essence of Moroccan culture, history, and hospitality.
                <br />
                <br />
                Our passionate chefs meticulously craft each dish using authentic spices, fragrant herbs, and the finest local ingredients. Whether you're savoring the succulent tagines, delighting in the intricacies of couscous, or relishing the warmth of traditional Moroccan mint tea, every bite tells a story of centuries-old culinary wisdom passed down through generations.

              </Description>
            }
            buttonRounded={false}
            textOnLeft={false}
            primaryButtonText="Latest Offers"
            imageSrc={sliderImage2}
            imageCss={imageCss}
            imageDecoratorBlob={true}
            imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
          />


        </ContentWithPaddingXl>

        <MainFeature2
          subheading={<Subheading>Welcome to Al Assala</Subheading>}
          heading={<>Taste the <HighlightedTextGreen>Magic of Morocco</HighlightedTextGreen></>}
          statistics={[
            {
              key: "Orders",
              value: "94000+",
            },
            {
              key: "Customers",
              value: "11000+"
            },
            {
              key: "Chefs",
              value: "1500+"
            }
          ]}
          primaryButtonText="Order Now"
          primaryButtonUrl="https://order.now.com"
          imageInsideDiv={false}
          imageSrc="https://images.unsplash.com/photo-1414235077428-338989a2e8c0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzNzI2fQ&auto=format&fit=crop&w=768&q=80"
          imageCss={Object.assign(tw`bg-cover`, imageCss)}
          imageContainerCss={tw`md:w-1/2 h-auto`}
          imageDecoratorBlob={true}
          imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
          textOnLeft={true}
        />

        <ContentWithPaddingSm>

          <Features
            heading={
              <>
                Amazing <HighlightedText>Services.</HighlightedText>
              </>
            }
            cards={[
              {
                imageSrc: "",
                title: "Restaurant",
                description: "The perfect place for leaving your worries and hunger behind. Step inside the intimate space created so that you can feel at home in Morocco.",
                url: "https://google.com"
              },
              {
                imageSrc: "",
                title: "Catering",
                description: "Celebrate your next special occasion with us. We will make your experience a unique one that your guests will remember for years to come.",
                url: "https://timerse.com"
              },
              {
                imageSrc: "",
                title: "Coffee Distribution",
                description: "To create a great cup of coffee: The Coffee Bean, The Coffee Machine, The Method. Discover all of the best with our partner Regina Cafè.",
                url: "https://reddit.com"
              }
            ]}

            imageContainerCss={tw`p-2!`}
            imageCss={tw`w-20! h-20!`}
          />


          <DownloadApp
            text={<>
              <HighlightedTextInverse>01 21 82 03 003</HighlightedTextInverse></>}
          />
          <DecoratorBlob1 />
          <DecoratorBlob2 />
        </ContentWithPaddingSm>

      </Container>

      <Footer />

    </AnimationRevealPage>
  );
}
