import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { css } from "styled-components/macro"; //eslint-disable-line
import ScrollToTop from "helpers/scrollToTop";

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

/* Inner Pages */
import AboutUsPage from "pages/AboutUs.js";
import Menu from "pages/Menu.js";
import ContactUsPage from "pages/ContactUs.js";


import MoroccanRestaurant from "assala/MoroccanRestaurant.js";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { ToastContainer } from "react-toastify";


export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;


  return (
    <>
      <GlobalStyles />
      <ToastContainer />
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/contact-us-now" element={<ContactUsPage />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/" element={<MoroccanRestaurant />} />
        </Routes>
      </Router>
    </>
  );
}

