import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import LogoImage from "../../images/logo.webp";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "../../images/instagram-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as TikTokIcon } from "../../images/tiktok-icon.svg";

import { Link as LinkReactRouter } from "react-router-dom";


const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5 text-gray-600`;
const WideColumn = tw(Column)
  `text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-48`;

const CompanyDescription = tw.p`mt-4 font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-600 text-gray-600 hover:bg-gray-900 transition duration-300 mr-2`}
  svg {
    ${tw`w-4 h-4`}
  }
`;


export default () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} />

          </LogoContainer>
          <CompanyDescription>
            The Moroccan Restaurant Al-Assala offers a chance to experience the legendary Moroccan cuisine in a soothing and inviting ambiance.
            <div style={{ color: '#ffffff00', display: 'inline' }}> ZC</div>
          </CompanyDescription>
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com/alassalarestaurant">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://instagram.com/al_assalarestaurant">
              <InstagramIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com/@alassalamoroccannet6176">
              <YoutubeIcon />
            </SocialLink>
            <SocialLink href="https://www.tiktok.com/@al_assala_restaurant">
              <TikTokIcon />
            </SocialLink>

          </SocialLinksContainer>
        </WideColumn>
        <Column>
          <ColumnHeading>Al Assala Restaurant</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <LinkReactRouter to="/about-us"><Link>Discover Moroccan Cuisine</Link></LinkReactRouter>
            </LinkListItem>
            <LinkListItem>
              <LinkReactRouter to="/menu"><Link>Our Delicious Menu</Link></LinkReactRouter>
            </LinkListItem>
            <LinkListItem>
              <a href="https://moroccofood.co.uk/contact-us-now" rel="noopener noreferrer"><Link>Order / Reservation</Link></a>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Opening Hours</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link>Everyday 11:00 – 22:00</Link>
            </LinkListItem>
            <LinkListItem>
              <Link>Closed on Tuesday</Link>
            </LinkListItem>
            <LinkListItem>
              <Link><a href="https://moroccofood.co.uk/contact-us-now" rel="noopener noreferrer">Stay at home, We deliver to you.</a></Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Contact Us</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="https://goo.gl/maps/RhfF5H9HZ4CjHoAJ6">537, Green Lane, B9 5PT Birmingham</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="tel:01218203003">01 21 82 03 003 </Link> |
              <Link href="tel:07466875691">  07 46 68 75 691</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="mailto:contact@moroccofood.co.uk">contact@moroccofood.co.uk</Link>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>

    </Container>
  );
};