import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";
import { Link } from "react-router-dom";

import serviceImage1 from "images/imgs1.webp";
import serviceImage2 from "images/imgs2.webp";
import serviceImage3 from "images/imgs3.webp";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap text-center`;
const Column = tw.div`mt-12 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center text-gray-600`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-56 lg:h-64 rounded rounded-b-none`
]);

const Details = tw.div`px-3 md:px-6 py-4 md:py-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-primary-500 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;

const Title = tw.h5`mt-0 leading-snug font-bold text-2xl text-gray-600`;
const Description = tw.p`mt-2 text-base text-gray-600`;
const NavLink = styled(PrimaryButtonBase).attrs({ as: "a" })`
  ${tw`inline-block mt-4 text-sm font-semibold bg-primary-500 hocus:bg-primary-700`}
`

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default ({
  subheading = "Blog",
  heading = <>We Love <span tw="text-primary-500">Writing.</span></>,
  description = "Experience, Indulge, Elevate: Discover Al Assala's Culinary Offerings and Partnerships.",

}) => {
  const blogPosts = [
    {
      imageSrc: serviceImage1,
      title: "Restaurant Experience",
      description: "Embark on a sensory voyage that allows your taste buds to wander through the captivating realm of Moroccan flavors, aromas, and traditions.",
      url: "/contact-us-now",
      button: "Explore Our Restaurant"
    },
    {
      imageSrc: serviceImage2,
      title: "Catering Services",
      description: "Whether it's a wedding, corporate gathering, or special celebration, our team brings the magic of Morocco to your next event.",
      url: "/contact-us-now",
      button: "Let's Celebrate You!"
    },
    {
      imageSrc: serviceImage3,
      title: "Coffee Distribution",
      description: "Al Assala's collaboration with Regina Café brings you an exquisite range of Italian coffee beans and high-quality coffee machines.",
      url: "/contact-us-now",
      button: "Discover Regina Café"
    }
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>{heading}</HeadingTitle>
          <HeadingDescription>{description}</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={post.imageSrc} />
                <Details>
                  <Title>{post.title}</Title>
                  <Description>{post.description}</Description>
                  <Link to={post.url}><NavLink>{post.button}</NavLink></Link>
                </Details>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
