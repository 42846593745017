import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import DownloadApp from "components/cta/GetStarted.js";
import Footer from "components/footers/SimpleFourColumn.js";


import sliderImage1 from "images/imgb1.webp";
import sliderImage2 from "images/imgb2.webp";




export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextGreen = tw.span`bg-primary-900 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-3 text-sm lg:text-base font-medium`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={<>Taste The Authenticity <HighlightedText>in Birmingham</HighlightedText></>}
        description="Indulge your senses in the vibrant and rich tapestry of Moroccan cuisine at Al Assala. Nestled in the heart of Birmingham, our restaurant invites you on an unforgettable gastronomic adventure through the flavors, aromas, and traditions of Morocco."
        imageSrc={sliderImage1}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Order Now"
        watchVideoButtonText="Restaurant Tour"
      />
      <MainFeature
        subheading={<Subheading>Established Since 2018</Subheading>}
        heading={
          <>
            Al-Assala Restaurant
            <wbr /> <HighlightedText>A Feast of Flavours</HighlightedText>
          </>
        }
        description={
          <Description>
            Drawing inspiration from the diverse regions of Morocco, Al Assala presents a menu that celebrates the country's culinary heritage while adding a modern twist. From the bustling streets of Marrakech to the serene coastal towns, our dishes capture the essence of Moroccan culture, history, and hospitality.
            <br />
            <br />
            Our passionate chefs meticulously craft each dish using authentic spices, fragrant herbs, and the finest local ingredients. Whether you're savoring the succulent tagines, delighting in the intricacies of couscous, or relishing the warmth of traditional Moroccan mint tea, every bite tells a story of centuries-old culinary wisdom passed down through generations.

          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Latest Offers"
        imageSrc={sliderImage2}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <TabGrid
        heading={
          <>
            Checkout Our <HighlightedText>MENU</HighlightedText>
          </>
        }
      />

      <DownloadApp
        text={<>
          <a href="https://moroccofood.co.uk/contact-us-now" target="_blank" rel="noopener noreferrer"><HighlightedTextInverse>ORDER NOW <small>ONLINE</small></HighlightedTextInverse></a></>}
      />

      <Features
        heading={
          <>
            Amazing <HighlightedText>Services.</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: "",
            title: "Restaurant",
            description: "The perfect place for leaving your worries and hunger behind. Step inside the intimate space created so that you can feel at home in Morocco.",
            url: "https://google.com"
          },
          {
            imageSrc: "",
            title: "Catering",
            description: "Celebrate your next special occasion with us. We will make your experience a unique one that your guests will remember for years to come.",
            url: "https://timerse.com"
          },
          {
            imageSrc: "",
            title: "Coffee Distribution",
            description: "To create a great cup of coffee: The Coffee Bean, The Coffee Machine, The Method. Discover all of the best with our partner Regina Cafè.",
            url: "https://reddit.com"
          }
        ]}

        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      <MainFeature2
        subheading={<Subheading>Welcome to Al Assala</Subheading>}
        heading={<>Taste the <HighlightedTextGreen>Magic of Morocco</HighlightedTextGreen></>}
        statistics={[
          {
            key: "Orders",
            value: "94000+",
          },
          {
            key: "Customers",
            value: "11000+"
          },
          {
            key: "Chefs",
            value: "1500+"
          }
        ]}
        primaryButtonText="Order Now"
        primaryButtonUrl="https://order.now.com"
        imageInsideDiv={false}
        imageSrc="https://images.unsplash.com/photo-1414235077428-338989a2e8c0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzNzI2fQ&auto=format&fit=crop&w=768&q=80"
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      <Testimonial
        subheading=""
        heading={<>Our Family <HighlightedText>Love Us.</HighlightedText></>}
      />

      <Footer />
    </AnimationRevealPage>
  );
}
