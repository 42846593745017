import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

import reviewImage1 from "../../images/review1.webp";
import reviewImage2 from "../../images/review2.webp";
import reviewImage3 from "../../images/review3.webp";

const Heading = tw(
  SectionHeading
)`text-gray-600`;
const Subheading = tw(SubheadingBase)`text-center text-gray-600`;
const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-16 lg:w-1/3`;
const Testimonial = tw.div`text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20 rounded-full`;
const Quote = tw.blockquote`mt-5 text-gray-600 font-medium`;
const CustomerName = tw.p`mt-5 text-gray-600 font-semibold uppercase text-sm tracking-wide`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default ({
  subheading = "Testimonials",
  heading = "Customer's Review",
  testimonials = [
    {
      imageSrc: reviewImage1,
      quote:
        "Wonderful little gem! The food is authentic & delicious. The family is very welcoming & accommodating. I travel miles to go there as I feel like I'm in Morocco. I Highly recommend if you want to experience authentic moroccan home cooking.",
      customerName: "Karima Himid-Wickett"
    },
    {
      imageSrc: reviewImage2,
      quote:
        "Have been to this restaurant for many time over the years, family run restaurant, always warm welcome with excellent food. Authentic Moroccan food, especially the Tagine. We enjoyed the family size Chicken Tagine, it give you taste of Morocco.",
      customerName: "Anthony Lau"
    },
    {
      imageSrc: reviewImage3,
      quote:
        "Small, cosy, and a place where you really get proper traditional Moroccan food without breaking your bank. Al-Assala, a family run Moroccan restaurant, is a must on the list if you’re visiting Birmingham and fancy eating something different yet tasty.",
      customerName: "Muhammad Omer"
    }
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        <Testimonials>
          {testimonials.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                <Image src={testimonial.imageSrc} />
                <Quote>"{testimonial.quote}"</Quote>
                <CustomerName>- {testimonial.customerName}</CustomerName>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
